import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  InputSearch,
  TableFilter,
  Skeleton,
  ButtonRefactored as Button
} from "@operata/adagio";
import DateTimePicker from "../DateTime/Wrapper";
import { getRanges } from "../DateTime/lib/utils/TimeFunctionUtils";
import TablePagination from "../Billing/TablePagination";
import { fetchCallStatsLog } from "../../actions/callstats";
import moment from "moment-timezone";
import { connect, useDispatch } from "react-redux";
import ModalWrapper from "../CallOnAPage/ModalWrapper";
import CallOnPageModal from "../CallOnAPage/CallOnAModal";
import { useHistory, useRouteMatch } from "react-router-dom";
import { createCSVDownload } from "../Datatables/utils";
import Close from "@material-ui/icons/FileCopyOutlined";
import { sortData, useDebounce } from "../../utils/datatables";

export const getSearchCriteria = (
  timeRange,
  agent,
  agentISP,
  contactId,
  page,
  pageSize
) => {
  timeRange = timeRange || ["Last 4 hours", "now-4h", "now"];
  const [, fromTime, toTime] = timeRange;

  const criteria = {
    fromTime,
    toTime,
    timezone: moment.tz.guess(),
    size: pageSize,
    from: page * pageSize
  };

  if (agent) {
    criteria.agent = agent;
  }

  if (agentISP) {
    criteria.agentISP = agentISP;
  }

  if (contactId) {
    criteria.contactId = contactId;
  }

  return criteria;
};

const CallStatsLog = ({ calls, recordCount, loading, path }) => {
  const [agentFilter, setAgentFilter] = useState("");
  const [agentISPFilter, setAgentISPFilter] = useState("");
  const [contactIDFilter, setContactIDFilter] = useState("");
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [timeRange, setTimeRange] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [sortKey, setSortKey] = useState("timestamp");
  const [sortOrder, setSortOrder] = useState("asc");

  const [selectedRange, setSelectedRange] = useState("Last 4 hours");
  const [isResetting, setIsResetting] = useState(false);

  const debouncedAgentFilter = useDebounce(agentFilter, 300, isResetting);
  const debouncedAgentISPFilter = useDebounce(agentISPFilter, 300, isResetting);
  const debouncedContactIDFilter = useDebounce(
    contactIDFilter,
    300,
    isResetting
  );

  function resetFilters() {
    setIsResetting(true);
    setAgentFilter("");
    setAgentISPFilter("");
    setContactIDFilter("");
    setPage(0);
    setSelectedRange("Last 4 hours");
    setTimeRange(["Last 4 hours", "now-4h", "now"]);
    setTimeout(() => {
      setIsResetting(false); // Allow network call after all states are reset
    }, 0);
  }

  let filteredCalls = calls || [];

  filteredCalls = debouncedAgentFilter
    ? filteredCalls.filter(call =>
        call.agent.toLowerCase().includes(debouncedAgentFilter.toLowerCase())
      )
    : filteredCalls;

  filteredCalls = debouncedAgentISPFilter
    ? filteredCalls.filter(call =>
        call.agentISP
          .toLowerCase()
          .includes(debouncedAgentISPFilter.toLowerCase())
      )
    : filteredCalls;

  filteredCalls = debouncedContactIDFilter
    ? filteredCalls.filter(call =>
        call.callContactId
          .toLowerCase()
          .includes(debouncedContactIDFilter.toLowerCase())
      )
    : filteredCalls;

  const downloadCSV = () => {
    const columns = [
      { name: "Timestamp", download: true },
      { name: "Agent", download: true },
      { name: "MOS", download: true },
      { name: "Contact ID", download: true },
      { name: "Agent ISP", download: true },
      { name: "Jitter", download: true },
      { name: "RTT", download: true },
      { name: "Packet Loss", download: true },
      { name: "Duration", download: true }
    ];

    const data = filteredCalls.map(call => ({
      data: [
        call.timestamp,
        call.agent,
        call.mos.avg.toFixed(2),
        call.callContactId,
        call.agentISP,
        call.jitter.avg,
        call.rtt.avg,
        call.totals.packetsLostFraction,
        call.durationSeconds
      ]
    }));

    const options = {
      downloadOptions: {
        filename: "tableDownload.csv",
        separator: ","
      }
    };

    createCSVDownload(columns, data, options);
  };

  const handleSortClick = key => {
    if (sortKey === key) {
      setSortOrder(prevOrder => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortKey(key);
      setSortOrder("asc");
    }
  };

  const history = useHistory();
  const match = useRouteMatch();

  useEffect(
    () => {
      if (isResetting) return;
      dispatch(
        fetchCallStatsLog(
          getSearchCriteria(
            timeRange,
            agentFilter,
            agentISPFilter,
            contactIDFilter,
            page,
            pageSize
          )
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      timeRange,
      page,
      pageSize,
      debouncedAgentFilter,
      debouncedAgentISPFilter,
      debouncedContactIDFilter,
      isResetting
    ]
  );

  const sortedCalls = filteredCalls.sort(sortData(sortKey, sortOrder));

  const handleButtonClick = id => {
    setContactId(id);
    history.push(`${path}/call/${id}`);
  };

  return (
    <>
      <>
        <TableFilter
          filters={
            <>
              <span
                onClick={setPickerOpen.bind(null, !pickerOpen)}
                className="time__link time__results"
              >
                {timeRange?.[0] === "Custom Range"
                  ? `${timeRange[1].format(
                      "DD-MM-YYYY HH:mm"
                    )} - ${timeRange[2].format("DD-MM-YYYY HH:mm")}`
                  : timeRange?.[0] || "Last 4 hours"}
              </span>

              <InputSearch
                value={agentFilter}
                placeholder="Agent"
                iconAfter={<Close />}
                onChange={event => setAgentFilter(event.target.value)}
                onClear={() => {
                  setAgentFilter("");
                  setPage(0);
                }}
                icon={false}
              />
              <InputSearch
                value={contactIDFilter}
                placeholder="Contact ID"
                iconAfter={<Close />}
                onChange={event => setContactIDFilter(event.target.value)}
                onClear={() => {
                  setContactIDFilter("");
                  setPage(0);
                }}
                icon={false}
              />
              <InputSearch
                value={agentISPFilter}
                placeholder="Agent ISP"
                iconAfter={<Close />}
                onChange={event => setAgentISPFilter(event.target.value)}
                onClear={() => {
                  setAgentISPFilter("");
                  setPage(0);
                }}
                icon={false}
              />
              <Button
                size="medium"
                type="secondary"
                disabled={
                  !timeRange &&
                  !agentFilter &&
                  !agentISPFilter &&
                  !contactIDFilter
                }
                onClick={() => {
                  resetFilters();
                }}
              >
                Reset
              </Button>
            </>
          }
          actions={
            <Button
              type="secondary"
              size="medium"
              disabled={!calls.length}
              iconAfter={<Table.DownloadIcon />}
              onClick={() => downloadCSV(calls)}
            >
              Download CSV
            </Button>
          }
        />
        <div
          style={{
            position: "relative",
            marginTop: "-50px",
            marginBottom: "66px"
          }}
        >
          <DateTimePicker
            rightAlign={false}
            dateRangeSelected={(range, start, end) => {
              if (range === "Custom Range") {
                setTimeRange([range, start, end]);
              } else {
                const timeRange = getRanges(new Date())[range];
                setTimeRange([range, timeRange[4], timeRange[5]]);
              }
              setSelectedRange(range); // Update the selected range state
              setPickerOpen(false);
            }}
            pickerOpen={pickerOpen ? "open" : ""}
            setPickerOpen={po => setPickerOpen(!!po)}
            currentRange={null}
            currentStartTime={timeRange?.[1]}
            currentEndTime={timeRange?.[2]}
            selectedRange={selectedRange}
          />
        </div>
        <Table layout="auto">
          <thead>
            <Table.Row>
              <Table.Header
                width={0}
                align="left"
                sortable
                sortIcon={
                  sortKey === "timestamp" ? (
                    sortOrder === "desc" ? (
                      <Table.SortDescendingIcon fontSize="inherit" />
                    ) : (
                      <Table.SortAscendingIcon fontSize="inherit" />
                    )
                  ) : null
                }
                onSortClick={() => handleSortClick("timestamp")}
              >
                Timestamp
              </Table.Header>
              <Table.Header
                width={120}
                minWidth={120}
                sortable
                sortIcon={
                  sortKey === "agent" ? (
                    sortOrder === "desc" ? (
                      <Table.SortDescendingIcon fontSize="inherit" />
                    ) : (
                      <Table.SortAscendingIcon fontSize="inherit" />
                    )
                  ) : null
                }
                onSortClick={() => handleSortClick("agent")}
              >
                Agent
              </Table.Header>
              <Table.Header minWidth={200}>Contact ID</Table.Header>
              <Table.Header
                width={120}
                sortable
                sortIcon={
                  sortKey === "agentISP" ? (
                    sortOrder === "desc" ? (
                      <Table.SortDescendingIcon fontSize="inherit" />
                    ) : (
                      <Table.SortAscendingIcon fontSize="inherit" />
                    )
                  ) : null
                }
                onSortClick={() => handleSortClick("agentISP")}
              >
                Agent ISP
              </Table.Header>
              <Table.Header
                width="10%"
                minWidth={50}
                align="right"
                sortable
                sortIcon={
                  sortKey === "mos.avg" ? (
                    sortOrder === "desc" ? (
                      <Table.SortDescendingIcon fontSize="inherit" />
                    ) : (
                      <Table.SortAscendingIcon fontSize="inherit" />
                    )
                  ) : null
                }
                onSortClick={() => handleSortClick("mos.avg")}
              >
                MOS
              </Table.Header>
              <Table.Header
                width="10%"
                minWidth={50}
                align="right"
                sortable
                sortIcon={
                  sortKey === "jitter.avg" ? (
                    sortOrder === "desc" ? (
                      <Table.SortDescendingIcon fontSize="inherit" />
                    ) : (
                      <Table.SortAscendingIcon fontSize="inherit" />
                    )
                  ) : null
                }
                onSortClick={() => handleSortClick("jitter.avg")}
              >
                Jitter (ms)
              </Table.Header>
              <Table.Header
                width="10%"
                minWidth={50}
                align="right"
                sortable
                sortIcon={
                  sortKey === "rtt.avg" ? (
                    sortOrder === "desc" ? (
                      <Table.SortDescendingIcon fontSize="inherit" />
                    ) : (
                      <Table.SortAscendingIcon fontSize="inherit" />
                    )
                  ) : null
                }
                onSortClick={() => handleSortClick("rtt.avg")}
              >
                RTT (ms)
              </Table.Header>
              <Table.Header
                width="10%"
                minWidth={50}
                align="right"
                sortable
                sortIcon={
                  sortKey === "totals.packetsLostFraction" ? (
                    sortOrder === "desc" ? (
                      <Table.SortDescendingIcon fontSize="inherit" />
                    ) : (
                      <Table.SortAscendingIcon fontSize="inherit" />
                    )
                  ) : null
                }
                onSortClick={() =>
                  handleSortClick("totals.packetsLostFraction")
                }
              >
                Packet Loss (%)
              </Table.Header>
              <Table.Header
                width="10%"
                minWidth={50}
                align="right"
                sortable
                sortIcon={
                  sortKey === "durationSeconds" ? (
                    sortOrder === "desc" ? (
                      <Table.SortDescendingIcon fontSize="inherit" />
                    ) : (
                      <Table.SortAscendingIcon fontSize="inherit" />
                    )
                  ) : null
                }
                onSortClick={() => handleSortClick("durationSeconds")}
              >
                Duration (s)
              </Table.Header>
              <Table.Header width={0} sticky />
            </Table.Row>
          </thead>
          <tbody>
            {loading ? (
              Array.from({ length: pageSize }, (_, i) => (
                <Table.Row key={i}>
                  <Table.Cell>
                    <Skeleton loading width={120} height={16} />
                  </Table.Cell>
                  <Table.Cell align="center">
                    <Skeleton loading width={120} height={16} />
                  </Table.Cell>
                  <Table.Cell align="center">
                    <Skeleton loading width={120} height={16} />
                  </Table.Cell>
                  <Table.Cell align="center">
                    <Skeleton loading width={120} height={16} />
                  </Table.Cell>
                  <Table.Cell align="center">
                    <Skeleton loading width={120} height={16} />
                  </Table.Cell>
                  <Table.Cell align="center">
                    <Skeleton loading width={60} height={16} />
                  </Table.Cell>
                  <Table.Cell align="center">
                    <Skeleton loading width={60} height={16} />
                  </Table.Cell>
                  <Table.Cell align="center">
                    <Skeleton loading width={60} height={16} />
                  </Table.Cell>
                  <Table.Cell align="center">
                    <Skeleton loading width={60} height={16} />
                  </Table.Cell>
                  <Table.Cell sticky>
                    <Skeleton loading width={120} height={16} />
                  </Table.Cell>
                </Table.Row>
              ))
            ) : sortedCalls.length ? (
              sortedCalls.map((call, index) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    <div>
                      {call.timestamp ? (
                        moment(call.timestamp).format("DD-MM-YYYY")
                      ) : (
                        <span>N/A</span>
                      )}
                    </div>
                    <div>
                      {call.timestamp ? (
                        moment(call.timestamp).format("HH:mm:ss")
                      ) : (
                        <span>N/A</span>
                      )}
                    </div>
                  </Table.Cell>
                  <Table.Cell>{call.agent}</Table.Cell>
                  <Table.Cell>{call.callContactId}</Table.Cell>
                  <Table.Cell>{call.agentISP}</Table.Cell>
                  <Table.Cell align="right">
                    {call.mos.avg.toFixed(2)}
                  </Table.Cell>
                  <Table.Cell align="right">{call.jitter.avg}</Table.Cell>
                  <Table.Cell align="right">{call.rtt.avg}</Table.Cell>
                  <Table.Cell align="right">
                    {call.totals.packetsLostFraction}
                  </Table.Cell>
                  <Table.Cell align="right">{call.durationSeconds}</Table.Cell>
                  <Table.Cell sticky>
                    <Button
                      type="tertiary"
                      size="small"
                      onClick={() => handleButtonClick(call.callContactId)} // Pass the appropriate contactId here
                    >
                      Details
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              // it will complain about using the wrong colspan here but it is correct
              <Table.RowEmpty colspan={9}>No data Found</Table.RowEmpty>
            )}
          </tbody>
        </Table>
        <TablePagination
          pageSize={pageSize}
          setPageSize={setPageSize}
          page={page}
          setPage={setPage}
          count={recordCount || 0}
        />
      </>
      <>
        {contactId && (
          <ModalWrapper onClose={() => setContactId(null)}>
            <CallOnPageModal
              {...calls}
              history={history}
              match={match}
              path={`${path}/call/${contactId}`}
              contactId={contactId}
              onClose={() => setContactId(null)}
            />
          </ModalWrapper>
        )}
      </>
    </>
  );
};

CallStatsLog.propTypes = {
  fetchCallStatsLog: PropTypes.func,
  calls: PropTypes.object,
  recordCount: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  path: PropTypes.object
};

const mapDispatchToProps = {
  fetchCallStatsLog
};

const mapStateToProps = state => ({
  calls: state.callstats.log || [],
  recordCount: state.callstats.logRecCount,
  loading: state.progress.loading
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CallStatsLog);
