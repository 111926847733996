import axios, { AxiosInstance } from "axios";
import { auth } from "../auth/Auth";

class AxiosProxy {
  private axiosInstance: AxiosInstance | undefined;
  private regionInstances: { [key: string]: AxiosInstance } = {};

  createInstance() {
    this.axiosInstance = axios.create({
      baseURL: window.location.host.includes("localhost") ? process.env.REACT_APP_API_URL :  "https://" + window.location.host.replace(/^app/, "api"),
      responseType: "application/json",
      headers: auth.getAuthorizationHeaders()
    });

    // recreate axios instance after token expires
    setTimeout(() => {
      this.createInstance();
    }, auth.tokenExpirationSecs * 1000);
  }

  getInstance(): AxiosInstance {
    if (!this.axiosInstance) {
      this.createInstance();
    }
    return (this.axiosInstance as AxiosInstance);
  }
}

export let axiosProxy = new AxiosProxy();
