import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment-timezone";

import DateTimePicker from "../DateTime/Wrapper";

import { fetchGroupBilling } from "../../actions/groups";

import {
  ButtonRefactored,
  Table,
  InputSelect,
  TableFilter,
  BrickCopy,
  Skeleton,
  Stack
} from "@operata/adagio";

import TablePagination from "./TablePagination";
import { getRanges } from "../DateTime/lib/utils/TimeFunctionUtils";
import { createCSVDownload } from "../Datatables/utils";

const Paginate = ({ pageSize, page }) => array =>
  array.slice(pageSize * page, pageSize * (page + 1));

const getSearchCriteria = timeRange => {
  timeRange = timeRange || ["Last 30 Days", "now-30d", "now"];
  const [, fromTime, toTime] = timeRange;
  return {
    fromTime,
    toTime,
    timezone: moment.tz.guess()
  };
};

const downloadCSV = groups => {
  const columns = [
    {
      name: "Group",
      download: true
    },
    {
      name: "Name",
      download: true
    },
    {
      name: "Cloud Collector Mins",
      download: true
    },
    {
      name: "Agent Collector Mins",
      download: true
    },
    {
      name: "Billing Rounded Mins",
      download: true
    },
    {
      name: "Heartbeat Tests",
      download: true
    },
    {
      name: "Agent Count",
      download: true
    }
  ];

  const data = groups.map(group => {
    return {
      data: [
        group.GroupID,
        group.GroupName,
        group.AgentInteractionDurationRoundedMin,
        group.OperataStatsDurationRoundedMin,
        group.DurationRoundedMin,
        group.HeartbeatTestCount,
        group.AgentCount
      ]
    };
  });

  const options = {
    downloadOptions: {
      filename: "tableDownload.csv",
      separator: ","
    }
  };

  createCSVDownload(columns, data, options);
};

const BillingGroups = ({ groups, fetchGroupBilling, loading }) => {
  const groupNames = [...new Set(groups.map(group => group.GroupName))];

  const [groupFilter, setGroupFilter] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [timeRange, setTimeRange] = useState(null);
  const [pickerOpen, setPickerOpen] = useState(false);

  useEffect(
    () => {
      fetchGroupBilling(getSearchCriteria(timeRange));
    },
    [fetchGroupBilling, timeRange]
  );

  const filteredGroups = groupFilter
    ? groups.filter(group => group.GroupName === groupFilter)
    : groups;

  const paginatedGroups = Paginate({ pageSize, page })(filteredGroups);

  return (
    <>
      <TableFilter
        filters={
          <>
            <span
              onClick={setPickerOpen.bind(null, !pickerOpen)}
              className="time__link time__results"
            >
              {timeRange?.[0] === "Custom Range"
                ? `${timeRange[1].format(
                    "DD-MM-YYYY HH:mm"
                  )} - ${timeRange[2].format("DD-MM-YYYY HH:mm")}`
                : timeRange?.[0] || "Last 30 Days"}
            </span>
            <InputSelect
              placeholder="Group Name"
              selectedValue={groupFilter}
              setSelectedValue={groupFilter => {
                setGroupFilter(groupFilter);
                setPage(0);
              }}
            >
              {groupNames.map((group, index) => (
                <InputSelect.Option key={index} value={group} />
              ))}
            </InputSelect>
            <ButtonRefactored
              size="medium"
              type="secondary"
              disabled={!timeRange && !groupFilter}
              onClick={() => {
                setGroupFilter(null);
                setPage(0);
                setTimeRange(null);
              }}
            >
              Reset
            </ButtonRefactored>
          </>
        }
        actions={
          <ButtonRefactored
            type="secondary"
            size="medium"
            disabled={!groups.length}
            onClick={() => downloadCSV(groups)}
            iconAfter={<Table.DownloadIcon />}
          >
            Download CSV
          </ButtonRefactored>
        }
      />
      <div
        style={{
          position: "relative",
          marginTop: "-50px",
          marginBottom: "66px"
        }}
      >
        <DateTimePicker
          rightAlign={false}
          dateRangeSelected={(selectedRange, start, end) => {
            if (selectedRange === "Custom Range") {
              setTimeRange([selectedRange, start, end]);
            } else {
              const timeRange = getRanges(new Date())[selectedRange];
              setTimeRange([selectedRange, timeRange[4], timeRange[5]]);
            }
            setPickerOpen(false);
          }}
          pickerOpen={pickerOpen ? "open" : ""}
          setPickerOpen={po => {
            return setPickerOpen(!!po);
          }}
          currentRange={null}
          currentStartTime={timeRange?.[1]}
          currentEndTime={timeRange?.[2]}
        />
      </div>
      <Table>
        <thead>
          <Table.Row>
            <Table.Header width="280">Group Name</Table.Header>
            <Table.Header width="auto" align="right">
              Cloud Collector Mins
            </Table.Header>
            <Table.Header width="auto" align="right">
              Agent Collector Mins
            </Table.Header>
            <Table.Header width="auto" align="right">
              Billing Rounded Mins
            </Table.Header>
            <Table.Header width="auto" align="right">
              Agent Rounded Mins
            </Table.Header>
            <Table.Header width="auto" align="right">
              Cloud Rounded Mins
            </Table.Header>
            <Table.Header width="auto" align="right">
              Heartbeat Tests
            </Table.Header>
            <Table.Header width="auto" align="right">
              Agent Count
            </Table.Header>
          </Table.Row>
        </thead>
        <tbody>
          {loading ? (
            Array.from({ length: pageSize }, (_, i) => (
              <Table.Row key={i}>
                <Table.Cell>
                  <Stack gap="8">
                    <Skeleton loading width={80} height={20} />
                    <Skeleton loading width={265} height={20} />
                  </Stack>
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
              </Table.Row>
            ))
          ) : paginatedGroups.length ? (
            paginatedGroups.map((group, index) => (
              <Table.Row key={index}>
                <Table.Cell>
                  {group.GroupID ? (
                    <BrickCopy
                      title={group.GroupName}
                      icon={<BrickCopy.CopyIcon />}
                      onClick={() =>
                        navigator.clipboard.writeText(group.GroupID)
                      }
                    >
                      {group.GroupID}
                    </BrickCopy>
                  ) : (
                    <span>N/A</span>
                  )}
                </Table.Cell>
                <Table.Cell align="right">
                  {group.AgentInteractionDurationRoundedMin}
                </Table.Cell>
                <Table.Cell align="right">
                  {group.OperataStatsDurationRoundedMin}
                </Table.Cell>
                <Table.Cell align="right">
                  {group.DurationRoundedMin}
                </Table.Cell>
                <Table.Cell align="right">
                  {group.AgentDurationRoundedMin}
                </Table.Cell>
                <Table.Cell align="right">
                  {group.CloudDurationRoundedMin}
                </Table.Cell>
                <Table.Cell align="right">
                  {group.HeartbeatTestCount}
                </Table.Cell>
                <Table.Cell align="right">{group.AgentCount}</Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.RowEmpty colspan={6}>No data Found</Table.RowEmpty>
          )}
        </tbody>
      </Table>
      <TablePagination
        pageSize={pageSize}
        setPageSize={setPageSize}
        page={page}
        setPage={setPage}
        count={filteredGroups.length}
      />
    </>
  );
};

BillingGroups.propTypes = {
  fetchGroupBilling: PropTypes.func,
  groups: PropTypes.array,
  loading: PropTypes.bool.isRequired
};

const mapDispatchToProps = {
  fetchGroupBilling
};

const mapStateToProps = state => ({
  groups: state.groups.billing || [],
  loading: state.progress.loading
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingGroups);
