import { getGroupById } from "./groups";
import { axiosProxy } from "../axios/AxiosProxy";
import { SHOW_PROGRESS } from "./progress";

export const USERS_RECEIVED = "USERS_RECEIVED";
export const USER_RECEIVED = "USER_RECEIVED";
export const USER_CREATED = "USER_CREATED";
export const ERROR_USER_CREATED = "ERROR_USER_CREATED";
export const USER_UPDATED = "USER_UPDATED";
export const USER_REMOVED = "USER_REMOVED";
export const RESET_USER_FORM_STATE = "RESET_USER_FORM_STATE";

export const userActionRequested = () => ({
  type: SHOW_PROGRESS
});

export const usersReceived = userList => ({
  type: USERS_RECEIVED,
  userList: userList
});

export const userReceived = user => ({
  type: USER_RECEIVED,
  user: user
});

export const userCreated = user => ({
  type: USER_CREATED,
  user: user
});

export const errorUserCreated = message => ({
  type: ERROR_USER_CREATED,
  message: message
});

export const userUpdated = user => ({
  type: USER_UPDATED,
  user: user
});

export const userRemoved = id => ({
  type: USER_REMOVED,
  id: id
});

export const resetState = () => ({
  type: RESET_USER_FORM_STATE
});

//redux-thunk
export const fetchUsers = () => {
  return dispatch => {
    dispatch(userActionRequested());
    return getUsers().then(resp => {
      dispatch(usersReceived(resp.data));
    });
  };
};

export const fetchUser = id => {
  return dispatch => {
    dispatch(userActionRequested());
    return getUserById(id).then(resp => {
      dispatch(userReceived(resp.data));
    });
  };
};

export const fetchProfile = id => {
  return dispatch => {
    dispatch(userActionRequested());
    return getUserProfile(id).then(resp => {
      dispatch(userReceived(resp.data));
    });
  };
};

export const initialiseMemberForm = groupId => {
  return dispatch => {
    dispatch(userActionRequested());
    return getGroupById(groupId).then(resp => {
      let user = {};
      user["isMember"] = true;
      user["groups"] = [resp.data];
      dispatch(userReceived(user));
    });
  };
};

export const updateUserProfile = user => {
  return dispatch => {
    dispatch(userActionRequested());

    return putUserProfile(user)
      .then(() => {
        dispatch(
          userUpdated({
            name: user.name,
            email: user.email,
            role: user.role
          })
        );
      })
      .catch(() => {
        dispatch(
          errorUserCreated("An error occurred while updating user profile")
        );
      });
  };
};

export const createOrUpdateUser = user => {
  return dispatch => {
    dispatch(userActionRequested());

    if (!user["role"]) {
      user["role"] = "Group Admin";
    }

    if (user.id > 0) {
      return putUser(user)
        .then(resp => {
          dispatch(userUpdated(resp.data));
        })
        .catch(() => {
          dispatch(errorUserCreated("An error occurred while saving the user"));
        });
    } else {
      if (user["isMember"]) {
        return handlePostUser(user, dispatch);
      } else {
        dispatch(errorUserCreated("An error occurred while saving the user"));
      }
    }
  };
};

export const removeUser = id => {
  return dispatch => {
    dispatch(userActionRequested());
    return deleteUser(id).then(() => {
      dispatch(userRemoved(id));
    });
  };
};

const getUsers = () => {
  return axiosProxy.getInstance().get("/users");
};

const getUserById = id => {
  return axiosProxy.getInstance().get("/users/" + id);
};

const postUser = user => {
  return axiosProxy.getInstance().post("/users", user);
};

const putUser = user => {
  return axiosProxy.getInstance().put("/users", user);
};

const getUserProfile = () => {
  return axiosProxy.getInstance().get("/users/profile");
};

const putUserProfile = user => {
  return axiosProxy.getInstance().put("/users/profile", user);
};

const deleteUser = id => {
  return axiosProxy.getInstance().delete("/users/" + id);
};

const handlePostUser = (user, dispatch) => {
  return postUser(user)
    .then(resp => {
      dispatch(userCreated(resp.data));
    })
    .catch(() => {
      dispatch(errorUserCreated("An error occurred while creating the user"));
    });
};
