import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import {
  downloadLogsFetched,
  fetchAgentCCPLogs,
  getAgentCCPLogs,
  showProgress
} from "../../actions/agent";
import {
  ButtonRefactored as Button,
  Skeleton,
  Table,
  PageFilter,
  HistoryIcon,
  Chip
} from "@operata/adagio";
import DateTimePicker from "../DateTime/Wrapper";
import { getRanges } from "../DateTime/lib/utils/TimeFunctionUtils";
import TablePagination from "../Billing/TablePagination";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { sortData, useDebounce } from "../../utils/datatables";

const getSearchCriteria = (timeRange, filters, page, pageSize) => {
  timeRange = timeRange || ["Last 4 hours", "now-4h", "now"];
  const [, fromTime, toTime] = timeRange;

  const criteria = {
    fromTime,
    toTime,
    timezone: moment.tz.guess(),
    size: pageSize,
    from: page * pageSize
  };

  filters.forEach(({ field, value }) => {
    criteria[field] = value;
  });

  return criteria;
};

const AgentLogs = ({ logs, recordCount, loading }) => {
  const logLevels = [
    "TRACE",
    "DEBUG",
    "INFO",
    "LOG",
    "WARN",
    "ERROR",
    "CRITICAL"
  ];

  const [agentFilter, setAgentFilter] = useState("");
  const [logLevelFilter, setLogLevelFilter] = useState(null);
  const dispatch = useDispatch();
  const [pickerOpen, setPickerOpen] = useState(false);
  const [timeRange, setTimeRange] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortKey, setSortKey] = useState("time");
  const [sortOrder, setSortOrder] = useState("asc");
  const [filterChange, setFilterChange] = useState(false);
  const [selectedField, setSelectedField] = useState('');
  const [selectedOperator, setSelectedOperator] = useState('');
  const [value, setValue] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);

  const [selectedRange, setSelectedRange] = useState("Last 4 hours");
  const [isResetting, setIsResetting] = useState(false);

  const debouncedAgentFilter = useDebounce(agentFilter, 300, isResetting);
  const debouncedLogLevelFilter = useDebounce(logLevelFilter, 300, isResetting);

  function resetFilters() {
    setIsResetting(true);
    setAgentFilter("");
    setLogLevelFilter(null);
    setActiveFilters([]);
    setPage(0);
    setSelectedRange("Last 4 hours");
    setTimeRange(["Last 4 hours", "now-4h", "now"]);
    setTimeout(() => {
      setIsResetting(false);
    }, 0);
  }

  const handleSortClick = key => {
    if (sortKey === key) {
      setSortOrder(prevOrder => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortKey(key);
      setSortOrder("asc");
    }
  };

  const handleAddFilter = () => {
    const newFilter = {
      field: selectedField,
      operator: selectedOperator,
      value: value,
    };

    if (isEditing && editingIndex !== null) {
      const updatedFilters = [...activeFilters];
      updatedFilters[editingIndex] = newFilter;
      setActiveFilters(updatedFilters);
    } else {
      setActiveFilters([...activeFilters, newFilter]);
    }

    resetForm();
  };

  const handleEditFilter = (index) => {
    const filterToEdit = activeFilters[index];
    setSelectedField(filterToEdit.field);
    setSelectedOperator(filterToEdit.operator);
    setValue(filterToEdit.value);
    setIsFilterOpen(true);
    setIsEditing(true);
    setEditingIndex(index);
  };

  const handleNewFilterClick = () => {
    resetForm();
    setIsFilterOpen(true);
  };

  const resetForm = () => {
    setSelectedField('');
    setSelectedOperator('');
    setValue('');
    setIsFilterOpen(false);
    setIsEditing(false);
    setEditingIndex(null);
  };

  const isSaveDisabled = !selectedField || !selectedOperator || !value;

  const handleDeleteFilter = (indexToDelete, event) => {
    event.stopPropagation();
    setActiveFilters(activeFilters.filter((_, index) => index !== indexToDelete));
  };

  const applyFilters = (logs, filters) => {
    const filtersByField = filters.reduce((acc, filter) => {
      if (!acc[filter.field]) {
        acc[filter.field] = [];
      }
      acc[filter.field].push(filter);
      return acc;
    }, {});

    return logs.filter(log => {
      return Object.keys(filtersByField).every(field => {
        const fieldFilters = filtersByField[field];

        return fieldFilters.some(filter => {
          const logValue = log[field]?.toString().toLowerCase();
          const filterValue = filter.value.toLowerCase();

          switch (filter.operator) {
            case '=':
              return logValue === filterValue;
            case '!=':
              return logValue !== filterValue;
            case '<':
              return logValue < filterValue;
            case '>':
              return logValue > filterValue;
            case '<=':
              return logValue <= filterValue;
            case '>=':
              return logValue >= filterValue;
            case 'contains':
              return logValue.includes(filterValue);
            default:
              return true;
          }
        });
      });
    });
  };

  const handleLogDownload = async () => {
    dispatch(showProgress());

    let logs = [];
    let page = 0;
    let batchSize = 1000;
    const results = await getAgentCCPLogs(
      getSearchCriteria(timeRange, activeFilters, page, batchSize)
    );
    logs.push(...results.data);

    createJSONDownload(logs);

    dispatch(downloadLogsFetched());
  };

  const createJSONDownload = logs => {
    const blob = new Blob([JSON.stringify(logs)], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "agent-logs.json";
    a.click();
    a.remove();
  };

  useEffect(
    () => {
      dispatch(
        fetchAgentCCPLogs(
          getSearchCriteria(
            timeRange,
            activeFilters,
            page,
            pageSize
          )
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      timeRange,
      page,
      pageSize,
      debouncedAgentFilter,
      debouncedLogLevelFilter,
      activeFilters
    ]
  );

  useEffect(
    () => {
      setFilterChange(true);
    },
    [agentFilter, logLevelFilter]
  );

  useEffect(
    () => {
      if (filterChange) {
        setPage(0);
        setFilterChange(false);
      }
    },
    [filterChange]
  );

  let filteredLogs = applyFilters(logs, activeFilters);

  const sortedLogs = filteredLogs.sort(sortData(sortKey, sortOrder));

  console.log(timeRange);

  filteredLogs = debouncedAgentFilter
    ? filteredLogs.filter(log =>
        log.agent.toLowerCase().includes(debouncedAgentFilter.toLowerCase())
      )
    : filteredLogs;

  filteredLogs = logLevelFilter
    ? filteredLogs.filter(log =>
        log.level.toLowerCase().includes(logLevelFilter.toLowerCase())
      )
    : filteredLogs;


  return (
    <>
      <PageFilter
        onNewFilterButtonClick={handleNewFilterClick}
        primary={
          <>
            <Button
              type="tertiary"
              icon={<HistoryIcon/>}
              onClick={setPickerOpen.bind(null, !pickerOpen)}
            >
              {selectedRange}
            </Button>
          </>
        }
        filters={
          <>
            <PageFilter.FilterCreate
              selectedField={selectedField}
              setSelectedField={setSelectedField}
              selectedOperator={selectedOperator}
              setSelectedOperator={setSelectedOperator}
              value={value}
              onCancel={() => setIsFilterOpen(false)}
              setValue={setValue}
              onApply={handleAddFilter}
              fields={["agent", "level"]}
              isOpen={isFilterOpen}
              isEditing={isEditing}
              isSaveDisabled={isSaveDisabled}
            />
            <>
              {activeFilters.map((filter, index) => (
                <Chip
                  key={index}
                  onClick={() => handleEditFilter(index)}
                  onDelete={(event) => handleDeleteFilter(index, event)}
                >
                  {`${filter.field} ${filter.operator} ${filter.value}`}
                </Chip>
              ))}
            </>
            <Button
              size="medium"
              type="secondary"
              disabled={activeFilters.length === 0}
              onClick={() => {
                resetFilters();
              }}
            >
              Reset
            </Button>
          </>
        }
        actions={
          <Button
            type="secondary"
            size="medium"
            disabled={!filteredLogs.length}
            iconAfter={<Table.DownloadIcon />}
            onClick={handleLogDownload}
          >
            Download JSON
          </Button>
        }
      />
      <div
        style={{
          position: "relative",
          marginTop: "-50px",
          marginBottom: "66px"
        }}
      >
        <DateTimePicker
          rightAlign={false}
          dateRangeSelected={(range, start, end) => {
            if (range === "Custom Range") {
              setTimeRange([range, start, end]);
            } else {
              const timeRange = getRanges(new Date())[range];
              setTimeRange([range, timeRange[4], timeRange[5]]);
            }
            setSelectedRange(range);
            setPickerOpen(false);
          }}
          pickerOpen={pickerOpen ? "open" : ""}
          setPickerOpen={po => setPickerOpen(!!po)}
          currentRange={null}
          currentStartTime={timeRange?.[1]}
          currentEndTime={timeRange?.[2]}
          selectedRange={selectedRange}
        />
      </div>
      <Table>
        <thead>
          <Table.Row>
            <Table.Header
              width={240}
              align="left"
              sortable
              sortIcon={
                sortOrder === "desc" ? (
                  <Table.SortDescendingIcon fontSize="inherit" />
                ) : (
                  <Table.SortAscendingIcon fontSize="inherit" />
                )
              }
              onSortClick={() => handleSortClick("time")}
            >
              Timestamp
            </Table.Header>
            <Table.Header
              width={120}
              sortable
              sortIcon={
                sortKey === "agent" ? (
                  sortOrder === "desc" ? (
                    <Table.SortDescendingIcon fontSize="inherit" />
                  ) : (
                    <Table.SortAscendingIcon fontSize="inherit" />
                  )
                ) : null
              }
              onSortClick={() => handleSortClick("agent")}
            >
              Agent
            </Table.Header>
            <Table.Header
              width={120}
              sortable
              sortIcon={
                sortKey === "level" ? (
                  sortOrder === "desc" ? (
                    <Table.SortDescendingIcon fontSize="inherit" />
                  ) : (
                    <Table.SortAscendingIcon fontSize="inherit" />
                  )
                ) : null
              }
              onSortClick={() => handleSortClick("level")}
            >
              Log Level
            </Table.Header>
            <Table.Header width="auto">Text</Table.Header>
          </Table.Row>
        </thead>
        <tbody>
          {loading ? (
            Array.from({ length: pageSize }, (_, i) => (
              <Table.Row key={i}>
                <Table.Cell>
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell align="center">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
              </Table.Row>
            ))
          ) : sortedLogs.length ? (
            sortedLogs.map((log, index) => (
              <Table.Row key={index}>
                <Table.Cell>
                  <div>
                    {log.time ? (
                      moment(log.time).format("DD-MM-YYYY")
                    ) : (
                      <span>N/A</span>
                    )}
                  </div>
                  <div>
                    {log.time ? (
                      moment(log.time).format("HH:mm:ss")
                    ) : (
                      <span>N/A</span>
                    )}
                  </div>
                </Table.Cell>
                <Table.Cell>{log.agent}</Table.Cell>
                <Table.Cell>{log.level}</Table.Cell>
                <Table.Cell>{log.text}</Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.RowEmpty colspan={4}>No data Found</Table.RowEmpty>
          )}
        </tbody>
      </Table>
      <TablePagination
        pageSize={pageSize}
        setPageSize={setPageSize}
        page={page}
        setPage={setPage}
        count={recordCount || 0}
      />
    </>
  );
};

AgentLogs.propTypes = {
  fetchAgentCCPLogs: PropTypes.func,
  logs: PropTypes.array,
  recordCount: PropTypes.number,
  loading: PropTypes.bool.isRequired
};

const mapDispatchToProps = {
  fetchAgentCCPLogs
};

const mapStateToProps = state => ({
  logs: state.agent.ccpLogs || [],
  recordCount: state.agent.ccpLogsRecCount,
  loading: state.progress.loading
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgentLogs);
