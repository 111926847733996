import { axiosProxy } from "../axios/AxiosProxy";
import { auth } from "../auth/Auth";

export const ACCESS_TOKEN_CHANGED = "ACCESS_TOKEN_CHANGED";
export const AUTH_SUCCESSFUL = "AUTH_SUCCESSFUL";
export const BI_AUTH_SUCCESSFUL = "BI_AUTH_SUCCESSFUL";
export const AUTH_UNSUCCESSFUL = "AUTH_UNSUCCESSFUL";

export const SWITCH_GROUP_SUCCESSFUL = "SWITCH_GROUP_SUCCESSFUL";
export const SWITCH_GROUP_UNSUCCESSFUL = "SWITCH_GROUP_UNSUCCESSFUL";

export const accessTokenChanged = token => ({
  type: ACCESS_TOKEN_CHANGED,
  token: token
});

export const authSuccessful = profile => ({
  type: AUTH_SUCCESSFUL,
  profile: profile
});

export const biAuthSuccessful = value => ({
  type: BI_AUTH_SUCCESSFUL,
  value
});

export const authUnsuccessful = () => ({
  type: AUTH_UNSUCCESSFUL
});

export const switchGroupSuccessful = (profile, requestedUrl) => ({
  type: SWITCH_GROUP_SUCCESSFUL,
  profile: profile,
  requestedUrl: requestedUrl
});

export const switchGroupUnsuccessful = () => ({
  type: SWITCH_GROUP_UNSUCCESSFUL
});

const intercomBoot = userData => {
  window.Intercom("boot", {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    email: userData.email,
    user_id: userData.id,
    name: userData.name,
    custom_launcher_selector: ".support"
  });
};

const initSegment = userData => {
  let authGroupId = auth.getCurrentGroupId();
  let authGroupName = auth.getCurrentGroupName();

  window.analytics.identify(userData.id, {
    name: userData.name,
    email: userData.email,
    groupName: authGroupName
  });

  window.analytics.group(authGroupId, {
    groupName: authGroupName
  });

  window.analytics.track("sign_in", {
    category: "account"
  });
};

export const loadUserProfileByEmail = () => {
  const userProfileApiString = window.location.host.includes("localhost") ? "/users/profile" : "https://" +
  window.location.host.replace(/^app/, "api") +
  "/users/profile"
;
  return dispatch => {
    return axiosProxy
    .getInstance()
    .get(userProfileApiString)
    .then(resp => {
        auth.setUserProfile(resp.data);
        dispatch(authSuccessful(resp.data));
        window.loadIntercomJS(process.env.REACT_APP_INTERCOM_APP_ID);
        window.setTimeout(() => {
          intercomBoot(resp.data);
          initSegment(resp.data);
        }, 1500);
      })
      .catch(error => {
        dispatch(authUnsuccessful());
        console.log(
          "An error occurred while loading the user profile: ",
          error
        );
      });
  };
};

export const logoutFromBI = () => {
  axiosProxy
    .getInstance()
    .get(
      "https://" +
        window.location.host.replace(/^app/, "bi") +
        "/clearSession/",
      {
        withCredentials: true
      }
    )
    .then(() => {
      console.log("BI logout successful.");
    })
    .catch(error => {
      console.log("An error occurred while loading the user profile: ", error);
    });
};

export const activeBISession = () => {
  return dispatch => {
    axiosProxy
      .getInstance()
      .get(
        process.env.REACT_APP_BI_URL.replace(
          ".operata.io",
          "-" + auth.getCurrentRegion() + ".operata.io"
        ) + "/activeSession/",
        {
          withCredentials: true
        }
      )
      .then(resp => {
        dispatch(biAuthSuccessful(resp.data));
      })
      .catch(error => {
        console.log(
          "An error occurred while calling wasOnceLoggedInToBI: ",
          error
        );
      });
  };
};

export const switchGroup = (userProfile, requestedUrl) => {
  return dispatch => {
    return axiosProxy
      .getInstance()
      .put(
        "https://" +
          window.location.host.replace(/^app/, "api") +
          "/users/switchGroup",
        userProfile
      )
      .then(() => {
        dispatch(switchGroupSuccessful(userProfile, requestedUrl));
      })
      .catch(error => {
        dispatch(switchGroupUnsuccessful());
        console.log("An error occurred while switching the group: ", error);
      });
  };
};
